import "styles/pages/page-history.scss";

import React from "react";
import classNames from "classnames";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TitleWithLine from "components/TitleWithLine";
import Banner from "components/Banner";

const History = ({ pageContext }) => {
  const history = pageContext.pageContent;
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        image={require("assets/images/history-img-1.png").default}
        title={pageContext.title}
      />
      <section className="section-about">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <TitleWithLine variantX="left" variantY="bottom">
                {history.historyTitle}
              </TitleWithLine>
              <div
                className="section-about__text"
                dangerouslySetInnerHTML={{ __html: history.historyText }}
              />
            </div>
            <div className="col-lg-6 offset-lg-1">
              <img
                className="img-fluid"
                src={history.historyImage?.sourceUrl}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-history">
        <div className="section-history__timeline"></div>
        <div className="container">
          {history.years.slice(0, -1).map((item, index, { length }) => {
            return (
              <div
                key={index}
                className={classNames(`row row-custom`, {
                  [`row-custom--odd`]: index % 2 === 1,
                  [`row-custom--even`]: index % 2 === 0,
                })}
              >
                <div className="col-lg-5 inner">
                  <img
                    className="img-fluid inner__img"
                    src={item.image?.sourceUrl}
                  />
                </div>
                <div className="col-lg-5 inner">
                  <TitleWithLine
                    variantY="top"
                    variantX={index % 2 === 0 ? "right" : "left"}
                  >
                    {item.year}
                  </TitleWithLine>
                  <p className="inner__text">{item.text}</p>
                </div>
              </div>
            );
          })}
          {history.years.map(
            (item, index, { length }) =>
              index + 1 === length && (
                <div key={index} className="row row-custom row-custom--last">
                  <div className="col-lg-5 inner">
                    <TitleWithLine variantY="top">{item.year}</TitleWithLine>
                    <p className="inner__text">{item.text}</p>
                  </div>
                </div>
              )
          )}
        </div>
      </section>

      <section className="section-banner">
        <Banner
          image={require("assets/images/logo-vertical.svg").default}
          bgColor="#f8faff"
        />
      </section>
    </Layout>
  );
};

export default History;
